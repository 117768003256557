import { resourceUrl } from "@/utils/dataExtractors"
export const providerContractIndexColumns = [
  {
    component: () => import("@/components/shared/table/cells/LinkCell.vue"),
    type: "Def",
    key: "contractLink",
    title: "Zum Vertrag",
    sortable: true,
    options: {
      contentFunction: (data:any) => {
        const crs = data.item.attributes.household.attributes.care_receivers
        const familyName = crs.map(cr => cr.attributes?.address?.attributes?.lastName).join(', ').trim()
        if (crs.length == 0) {return 'ERROR! Keine Patienten'}
        return familyName !== '' ? familyName : 'Name nicht gefunden'
        // return data.item.attributes.household.attributes.posting.attributes.visor.attributes.designation || 'Name nicht gefunden'
      },
      hrefFunction: (data: any) => resourceUrl(data.item)
    }
  },
  {
    component: () => import("@/components/shared/table/cells/LinkCell.vue"),
    type: "Def",
    key: "visorLink",
    title: "Zur Stelle",
    sortable: true,
    options: {
      contentFunction: (data:any) => {
        // const crs = data.item.attributes.household.attributes.care_receivers
        // const familyName = crs.map(cr => cr.attributes?.address?.attributes?.lastName).join(', ').trim()
        // if (crs.length == 0) {return 'ERROR! Keine Patienten'}
        // return familyName !== '' ? familyName : 'Name nicht gefunden'
        return data.item.attributes.household.attributes.posting.attributes.visor.attributes.designation || 'Name nicht gefunden'
      },
      hrefFunction: (data: any) => {
        const visor = data.item.attributes.household.attributes.posting.attributes.visor
        return resourceUrl(visor)
      }
    }
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "attributes.clientSigned",
    title: "Sig. Kd.",
    sortable: true,
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "attributes.agencySigned",
    title: "Sig. Ag.",
    sortable: true,
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "attributes.active",
    title: "Aktiv",
    sortable: true,
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "attributes.archived",
    title: "Archiviert",
    sortable: true,
  },
  // {
  //   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
  //   key: "attributes.agencyStart",
  //   title: "Ag. Start",
  //   sortable: true,
  //   options: {
  //     type: 'date'
  //   }
  // },
  // {
  //   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
  //   key: "attributes.clientStart",
  //   title: "Kd. Start",
  //   sortable: true,
  //   options: {
  //     type: 'date'
  //   }
  // },
]

export const internalContractIndexColumns = [
  {
    component: () => import("@/components/shared/table/cells/LinkCell.vue"),
    key: "meta.hhDesignation",
    title: "Vertrag mit HH",
    sortable: true,
    options: { hrefFunction: (data: any) => resourceUrl(data.item) }
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "meta.agencyName",
    title: "Agentur",
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "meta.seller",
    title: "VP",
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "attributes.createdAt",
    title: "Erstellungsdatum",
    sortable: true,
    options: {
      type: 'date'
    }
  },
  {
    component: () => import("@/components/shared/table/cells/IconCell.vue"),
    key: "attributes.clientSigned",
    title: "Sig. Kd.",
    sortable: true,
    options: {
      mappedIcons: [
        { value: false, stack: [{ icon: 'times', class: 'text-muted' }] },
        { value: true, stack: [{ icon: 'check', class: 'text-muted'}] },
      ]
    }
  },
  {
    component: () => import("@/components/shared/table/cells/IconCell.vue"),
    key: "attributes.agencySigned",
    title: "Sig. Ag.",
    sortable: true,
    options: {
      mappedIcons: [
        { value: false, stack: [{ icon: 'times', class: 'text-muted' }] },
        { value: true, stack: [{ icon: 'check', class: 'text-muted' }] },
      ]
    }
  },
  {
    component: () => import("@/components/shared/table/cells/IconCell.vue"),
    key: "attributes.active",
    title: "Aktiv",
    sortable: true,
    options: {
      mappedIcons: [
        { value: false, stack: [{ icon: 'times', class: 'text-muted'}] },
        { value: true, stack: [{ icon: 'check', class: 'text-muted'}] },
      ]
    }
  },
  {
    component: () => import("@/components/shared/table/cells/IconCell.vue"),
    key: "attributes.archived",
    title: "Archiviert",
    sortable: true,
    options: {
      mappedIcons: [
        { value: false, stack: [{ icon: 'times', class: 'text-muted'}] },
        { value: true, stack: [{ icon: 'check', class: 'text-muted'}] },
      ]
    }
  },
]

export const sellerContractIndexColumns = [
  {
    component: () => import("@/components/shared/table/cells/LinkCell.vue"),
    type: "Def",
    key: "attributes.household.attributes.designation",
    title: "Vertrag mit",
    sortable: true,
    options: { hrefFunction: (data: any) => resourceUrl(data.item) }
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "attributes.agency.attributes.name",
    title: "Agentur",
    sortable: true,
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "attributes.clientSigned",
    title: "Sig. Kd.",
    sortable: true,
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "attributes.agencySigned",
    title: "Sig. Ag.",
    sortable: true,
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "attributes.active",
    title: "Aktiv",
    sortable: true,
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "attributes.archived",
    title: "Archiviert",
    sortable: true,
  },
  // {
  //   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
  //   key: "attributes.agencyStart",
  //   title: "Ag. Start",
  //   sortable: true,
  //   options: {
  //     type: 'date'
  //   }
  // },
  // {
  //   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
  //   key: "attributes.clientStart",
  //   title: "Kd. Start",
  //   sortable: true,
  //   options: {
  //     type: 'date'
  //   }
  // },
]